<template>
  <section>
    <b-card>
      <b-row>
        <b-col cols="12" md="12">
          <b-form-group :label="$t('aircraft.select')" label-for="aircraft-select">
            <v-select
              v-model="aircraftDefined"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="aircraftOptions"
              :reduce="val => val.value"
              :clearable="false"
              :searchable="false"
              input-id="aircraft-select"
            />
          </b-form-group>
        </b-col>

        <b-col v-if="aircraftDefined === 'other'" cols="12" md="12">
          <b-form-group :label="$t('aircraft.name')" label-for="aircraft-name">
            <b-form-input id="aircraft-name" v-model="aircraftData.name" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <section v-show="aircraftDefined">
      <!-- Form -->
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <validation-observer ref="aircraftRules" tag="form">
          <b-row class="match-height">
            <b-col md="6" lg="6" sm="12">
              <b-card>
                <b-row>
                  <!-- Field: Category -->
                  <b-col sm="12" md="12" lg="12">
                    <div class="d-flex">
                      <h4 class="mb-1">{{ $t('aircraft.selected_aircraft') }} {{ aircraftDefined === 'other' ? aircraftData.name : aircraftDefined }}</h4>
                    </div>
                    <validation-provider #default="{ errors }" :name="$t('aircraft.category')" rules="required" class="validation-required">
                      <b-form-group :label="$t('aircraft.category')" label-for="aircraft-cat">
                        <b-form-input id="aircraft-cat" v-model="aircraftData.category" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- Field: Manufacturer -->
                  <b-col sm="12" md="12" lg="12">
                    <validation-provider #default="{ errors }" :name="$t('aircraft.manufacturer')" rules="required" class="validation-required">
                      <b-form-group :label="$t('aircraft.manufacturer')" label-for="aircraft-manufacturer">
                        <b-form-input id="aircraft-manufacturer" v-model="aircraftData.manufacturer" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- Field: Manufacturer -->
                  <b-col sm="12" md="12" lg="12">
                    <validation-provider #default="{ errors }" :name="$t('aircraft.mtow')" rules="required" class="validation-required">
                      <b-form-group :label="$t('aircraft.mtow')" label-for="aircraft-mtow">
                        <b-form-input id="aircraft-mtow" v-model="aircraftData.MTOW" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- Field: Manufacturer -->
                  <b-col sm="12" md="6" lg="6">
                    <validation-provider #default="{ errors }" :name="$t('aircraft.height')" rules="required" class="validation-required">
                      <b-form-group :label="$t('aircraft.height')" label-for="aircraft-height">
                        <b-form-input id="aircraft-height" v-model="aircraftData.height" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- Field: Manufacturer -->
                  <b-col sm="12" md="6" lg="6">
                    <validation-provider #default="{ errors }" :name="$t('aircraft.length')" rules="required" class="validation-required">
                      <b-form-group :label="$t('aircraft.length')" label-for="aircraft-length">
                        <b-form-input id="aircraft-length" v-model="aircraftData.length" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- Field: Wingspan -->
                  <b-col sm="12" md="6" lg="6">
                    <validation-provider #default="{ errors }" :name="$t('aircraft.wingspan')" rules="required" class="validation-required">
                      <b-form-group :label="$t('aircraft.wingspan')" label-for="aircraft-wingspan">
                        <b-form-input id="aircraft-wingspan" v-model="aircraftData.wingspan" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- Field: Take off distance -->
                  <b-col sm="12" md="6" lg="6">
                    <validation-provider #default="{ errors }" :name="$t('aircraft.distance_mtow')" rules="required" class="validation-required">
                      <b-form-group :label="$t('aircraft.distance_mtow')" label-for="aircraft-distance-mtow">
                        <b-form-input id="aircraft-distance-mtow" v-model="aircraftData.distanceMTOW" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- Field: Max fuel -->
                  <b-col sm="12" md="6" lg="6">
                    <validation-provider #default="{ errors }" :name="$t('aircraft.max_fuel')" rules="required" class="validation-required">
                      <b-form-group :label="$t('aircraft.max_fuel')" label-for="aircraft-max-fuel">
                        <b-form-input id="aircraft-max-fuel" v-model="aircraftData.maxFuel" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col sm="12" md="6" lg="6">
                    <b-form-group :label="$t('aircraft.measurement_unity')" label-for="aircraft-measurement">
                      <v-select
                        v-model="aircraftData.measurementUnity"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="measurementOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="aircraft-measurement"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col md="6" lg="6" sm="12">
              <b-card>
                <b-row>
                  <!-- Field: registration -->
                  <b-col sm="12" md="12" lg="12">
                    <div class="d-flex">
                      <h4 class="mb-1">
                        {{ $t('aircraft.information_about') }}
                      </h4>
                    </div>
                    <validation-provider #default="{ errors }" :name="$t('aircraft.registration')" rules="required" class="validation-required">
                      <b-form-group :label="$t('aircraft.registration')" label-for="aircraft-registration">
                        <b-form-input id="aircraft-registration" v-model="aircraftData.registration" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- Field: call_sign -->
                  <b-col sm="12" md="12" lg="12">
                    <validation-provider #default="{ errors }" :name="$t('aircraft.call_sign')" rules="required" class="validation-required">
                      <b-form-group :label="$t('aircraft.call_sign')" label-for="aircraft-call_sign">
                        <b-form-input id="aircraft-call_sign" v-model="aircraftData.callSign" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- Field: Homebase -->
                  <b-col sm="12" md="12" lg="12">
                    <validation-provider #default="{ errors }" :name="$t('aircraft.homebase')" rules="required" class="validation-required">
                      <b-form-group :label="$t('aircraft.homebase')" label-for="aircraft-homebase" :state="errors.length > 0 ? false : null">
                        <v-select
                          v-model="aircraftData.homebase"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="countryOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          input-id="aircraft-homebase"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- Field: Max fuel -->
                  <b-col sm="12" md="12" lg="12">
                    <validation-provider #default="{ errors }" :name="$t('aircraft.number_seat')" rules="required" class="validation-required">
                      <b-form-group :label="$t('aircraft.number_seat')" label-for="aircraft-number-seat">
                        <b-form-input id="aircraft-number-seat" v-model="aircraftData.numberSeat" :state="errors.length > 0 ? false : null" type="number" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="12" md="12">
                    <validation-provider #default="{ errors }" :name="$t('aircraft.fire_fighting_category')" rules="required" class="validation-required">
                      <b-form-group :label="$t('aircraft.fire_fighting_category')" label-for="aircraft-fireFighting" :state="errors.length > 0 ? false : null">
                        <v-select
                          v-model="aircraftData.fireFighting"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="fireFightingOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          :state="errors.length > 0 ? false : null"
                          input-id="aircraft-fireFighting"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="12" md="12">
                    <validation-provider #default="{ errors }" :name="$t('aircraft.aoc')" rules="required" class="validation-required">
                      <b-form-group :label="$t('aircraft.aoc')" label-for="aircraft-AOC">
                        <b-form-input id="aircraft-AOC" v-model="aircraftData.AOC" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col sm="12" lg="4" md="4">
                    <validation-provider #default="{ errors }" :name="$t('aircraft.fuel_type')" rules="required" class="validation-required">
                      <b-form-group :label="$t('aircraft.fuel_type')" label-for="aircraft-type_fuel" :state="errors.length > 0 ? false : null">
                        <v-select
                          v-model="aircraftData.typeFuel"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="typeFuelOptions"
                          :clearable="false"
                          multiple="true"
                          input-id="aircraft-type_fuel"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="12" lg="4" md="4">
                    <validation-provider #default="{ errors }" :name="$t('aircraft.fuel_volume')" rules="required" class="validation-required">
                      <b-form-group :label="$t('aircraft.fuel_volume')" label-for="aircraft-fuel_volume">
                        <b-form-input id="aircraft-fuel_volume" v-model="aircraftData.fuelVolume" :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col sm="12" lg="4" md="4">
                    <validation-provider #default="{ errors }" :name="$t('aircraft.measurement_unity')" rules="required" class="validation-required">
                      <b-form-group :label="$t('aircraft.measurement_unity')" label-for="aircraft-measurement" :state="errors.length > 0 ? false : null">
                        <v-select
                          v-model="aircraftData.fuelMeasurementUnity"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="measurementOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          input-id="aircraft-measurement"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="d-flex justify-content-end">
              <b-button class="d-flex align-items-center">
                <font-awesome-icon icon="ban" class="mr-50" />
                <span class="align-middle">{{ $t('action.cancel') }}</span>
              </b-button>

              <b-button class="d-flex align-items-center ml-1" type="submit" @click.prevent="validationForm">
                <font-awesome-icon icon="plane" class="mr-50" />
                <span class="align-middle">{{ $t('action.save_and_add') }}</span>
              </b-button>

              <b-button class="d-flex align-items-center ml-1" @click.prevent="validationForm('aircrafts')">
                <font-awesome-icon icon="arrow-right" class="mr-50" />
                <span class="align-middle">{{ $t('action.save_and_quit') }}</span>
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>
    </section>
  </section>
</template>

<script>
import { fetchAircraftRequest } from '@/request/globalApi/requests/aircraftRequests'

export default {
  name: 'AircraftForm',

  data() {
    return {
      aircraftDefined: '',
      customName: '',
      aircraftData: {
        fullName: '',
        name: '',
        email: '',
        role: null,
        currentPlan: null,
        company: '',
        contact: '',
      },
      aircraftOptions: [
        { label: 'Other', value: 'other' },
        { label: 'Airbus A 380', value: 'A380' },
        { label: 'JET 21', value: 'JET21' },
      ],
      measurementOptions: [{ label: 'Liters', value: 'litres' }],
      countryOptions: [{ label: 'France', value: 'france' }],
      fireFightingOptions: [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
      ],
      typeFuelOptions: [
        { label: 'Jet fuel', value: 'jetFuel' },
        { label: 'Avgas', value: 'avgas' },
        { label: 'TS 1', value: 'TS1' },
        { label: 'Biojet', value: 'biojet' },
      ],
    }
  },
  mounted() {
    if (this.$route.query.action === 'update') {
      this.fetchAircraft()
    }
  },
  methods: {
    fetchAircraft() {
      fetchAircraftRequest(this.$route.params.aircraft_id).then(response => {
        this.aircraftData = response.data
        this.aircraftDefined = this.aircraftData.name
      })
    },
    validationForm(nextRoute) {
      this.$refs.aircraftRules.validate().then(success => {
        if (success) {
          this.onSubmit(nextRoute)
        }
      })
    },
    onSubmit(nextRoute) {
      if (this.aircraftDefined !== 'other') {
        this.aircraftData.name = this.aircraftDefined
      }
      this.$store.dispatch('aircraft/addAircraft', this.aircraftData).then(() => {
        if (nextRoute) {
          this.$router.push({ name: nextRoute })
        }
        this.alert(this.$t('alert.save.success'))
      })
    },
  },
}
</script>

<style lang="scss">
#add-new-aircraft-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
