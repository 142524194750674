var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('aircraft.select'),"label-for":"aircraft-select"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.aircraftOptions,"reduce":function (val) { return val.value; },"clearable":false,"searchable":false,"input-id":"aircraft-select"},model:{value:(_vm.aircraftDefined),callback:function ($$v) {_vm.aircraftDefined=$$v},expression:"aircraftDefined"}})],1)],1),(_vm.aircraftDefined === 'other')?_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('aircraft.name'),"label-for":"aircraft-name"}},[_c('b-form-input',{attrs:{"id":"aircraft-name"},model:{value:(_vm.aircraftData.name),callback:function ($$v) {_vm.$set(_vm.aircraftData, "name", $$v)},expression:"aircraftData.name"}})],1)],1):_vm._e()],1)],1),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.aircraftDefined),expression:"aircraftDefined"}]},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit(_vm.onSubmit)}}},[_c('validation-observer',{ref:"aircraftRules",attrs:{"tag":"form"}},[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"6","lg":"6","sm":"12"}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('div',{staticClass:"d-flex"},[_c('h4',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('aircraft.selected_aircraft'))+" "+_vm._s(_vm.aircraftDefined === 'other' ? _vm.aircraftData.name : _vm.aircraftDefined))])]),_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('aircraft.category'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('aircraft.category'),"label-for":"aircraft-cat"}},[_c('b-form-input',{attrs:{"id":"aircraft-cat","state":errors.length > 0 ? false : null},model:{value:(_vm.aircraftData.category),callback:function ($$v) {_vm.$set(_vm.aircraftData, "category", $$v)},expression:"aircraftData.category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('aircraft.manufacturer'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('aircraft.manufacturer'),"label-for":"aircraft-manufacturer"}},[_c('b-form-input',{attrs:{"id":"aircraft-manufacturer","state":errors.length > 0 ? false : null},model:{value:(_vm.aircraftData.manufacturer),callback:function ($$v) {_vm.$set(_vm.aircraftData, "manufacturer", $$v)},expression:"aircraftData.manufacturer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('aircraft.mtow'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('aircraft.mtow'),"label-for":"aircraft-mtow"}},[_c('b-form-input',{attrs:{"id":"aircraft-mtow","state":errors.length > 0 ? false : null},model:{value:(_vm.aircraftData.MTOW),callback:function ($$v) {_vm.$set(_vm.aircraftData, "MTOW", $$v)},expression:"aircraftData.MTOW"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('aircraft.height'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('aircraft.height'),"label-for":"aircraft-height"}},[_c('b-form-input',{attrs:{"id":"aircraft-height","state":errors.length > 0 ? false : null},model:{value:(_vm.aircraftData.height),callback:function ($$v) {_vm.$set(_vm.aircraftData, "height", $$v)},expression:"aircraftData.height"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('aircraft.length'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('aircraft.length'),"label-for":"aircraft-length"}},[_c('b-form-input',{attrs:{"id":"aircraft-length","state":errors.length > 0 ? false : null},model:{value:(_vm.aircraftData.length),callback:function ($$v) {_vm.$set(_vm.aircraftData, "length", $$v)},expression:"aircraftData.length"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('aircraft.wingspan'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('aircraft.wingspan'),"label-for":"aircraft-wingspan"}},[_c('b-form-input',{attrs:{"id":"aircraft-wingspan","state":errors.length > 0 ? false : null},model:{value:(_vm.aircraftData.wingspan),callback:function ($$v) {_vm.$set(_vm.aircraftData, "wingspan", $$v)},expression:"aircraftData.wingspan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('aircraft.distance_mtow'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('aircraft.distance_mtow'),"label-for":"aircraft-distance-mtow"}},[_c('b-form-input',{attrs:{"id":"aircraft-distance-mtow","state":errors.length > 0 ? false : null},model:{value:(_vm.aircraftData.distanceMTOW),callback:function ($$v) {_vm.$set(_vm.aircraftData, "distanceMTOW", $$v)},expression:"aircraftData.distanceMTOW"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('aircraft.max_fuel'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('aircraft.max_fuel'),"label-for":"aircraft-max-fuel"}},[_c('b-form-input',{attrs:{"id":"aircraft-max-fuel","state":errors.length > 0 ? false : null},model:{value:(_vm.aircraftData.maxFuel),callback:function ($$v) {_vm.$set(_vm.aircraftData, "maxFuel", $$v)},expression:"aircraftData.maxFuel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('aircraft.measurement_unity'),"label-for":"aircraft-measurement"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.measurementOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"aircraft-measurement"},model:{value:(_vm.aircraftData.measurementUnity),callback:function ($$v) {_vm.$set(_vm.aircraftData, "measurementUnity", $$v)},expression:"aircraftData.measurementUnity"}})],1)],1)],1)],1)],1),_c('b-col',{attrs:{"md":"6","lg":"6","sm":"12"}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('div',{staticClass:"d-flex"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('aircraft.information_about'))+" ")])]),_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('aircraft.registration'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('aircraft.registration'),"label-for":"aircraft-registration"}},[_c('b-form-input',{attrs:{"id":"aircraft-registration","state":errors.length > 0 ? false : null},model:{value:(_vm.aircraftData.registration),callback:function ($$v) {_vm.$set(_vm.aircraftData, "registration", $$v)},expression:"aircraftData.registration"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('aircraft.call_sign'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('aircraft.call_sign'),"label-for":"aircraft-call_sign"}},[_c('b-form-input',{attrs:{"id":"aircraft-call_sign","state":errors.length > 0 ? false : null},model:{value:(_vm.aircraftData.callSign),callback:function ($$v) {_vm.$set(_vm.aircraftData, "callSign", $$v)},expression:"aircraftData.callSign"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('aircraft.homebase'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('aircraft.homebase'),"label-for":"aircraft-homebase","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.countryOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"aircraft-homebase","state":errors.length > 0 ? false : null},model:{value:(_vm.aircraftData.homebase),callback:function ($$v) {_vm.$set(_vm.aircraftData, "homebase", $$v)},expression:"aircraftData.homebase"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('aircraft.number_seat'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('aircraft.number_seat'),"label-for":"aircraft-number-seat"}},[_c('b-form-input',{attrs:{"id":"aircraft-number-seat","state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.aircraftData.numberSeat),callback:function ($$v) {_vm.$set(_vm.aircraftData, "numberSeat", $$v)},expression:"aircraftData.numberSeat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('aircraft.fire_fighting_category'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('aircraft.fire_fighting_category'),"label-for":"aircraft-fireFighting","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.fireFightingOptions,"reduce":function (val) { return val.value; },"clearable":false,"state":errors.length > 0 ? false : null,"input-id":"aircraft-fireFighting"},model:{value:(_vm.aircraftData.fireFighting),callback:function ($$v) {_vm.$set(_vm.aircraftData, "fireFighting", $$v)},expression:"aircraftData.fireFighting"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('aircraft.aoc'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('aircraft.aoc'),"label-for":"aircraft-AOC"}},[_c('b-form-input',{attrs:{"id":"aircraft-AOC","state":errors.length > 0 ? false : null},model:{value:(_vm.aircraftData.AOC),callback:function ($$v) {_vm.$set(_vm.aircraftData, "AOC", $$v)},expression:"aircraftData.AOC"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"sm":"12","lg":"4","md":"4"}},[_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('aircraft.fuel_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('aircraft.fuel_type'),"label-for":"aircraft-type_fuel","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.typeFuelOptions,"clearable":false,"multiple":"true","input-id":"aircraft-type_fuel","state":errors.length > 0 ? false : null},model:{value:(_vm.aircraftData.typeFuel),callback:function ($$v) {_vm.$set(_vm.aircraftData, "typeFuel", $$v)},expression:"aircraftData.typeFuel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","lg":"4","md":"4"}},[_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('aircraft.fuel_volume'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('aircraft.fuel_volume'),"label-for":"aircraft-fuel_volume"}},[_c('b-form-input',{attrs:{"id":"aircraft-fuel_volume","state":errors.length > 0 ? false : null},model:{value:(_vm.aircraftData.fuelVolume),callback:function ($$v) {_vm.$set(_vm.aircraftData, "fuelVolume", $$v)},expression:"aircraftData.fuelVolume"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"sm":"12","lg":"4","md":"4"}},[_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('aircraft.measurement_unity'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('aircraft.measurement_unity'),"label-for":"aircraft-measurement","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.measurementOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"aircraft-measurement","state":errors.length > 0 ? false : null},model:{value:(_vm.aircraftData.fuelMeasurementUnity),callback:function ($$v) {_vm.$set(_vm.aircraftData, "fuelMeasurementUnity", $$v)},expression:"aircraftData.fuelMeasurementUnity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"d-flex align-items-center"},[_c('font-awesome-icon',{staticClass:"mr-50",attrs:{"icon":"ban"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('action.cancel')))])],1),_c('b-button',{staticClass:"d-flex align-items-center ml-1",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('font-awesome-icon',{staticClass:"mr-50",attrs:{"icon":"plane"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('action.save_and_add')))])],1),_c('b-button',{staticClass:"d-flex align-items-center ml-1",on:{"click":function($event){$event.preventDefault();return _vm.validationForm('aircrafts')}}},[_c('font-awesome-icon',{staticClass:"mr-50",attrs:{"icon":"arrow-right"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('action.save_and_quit')))])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }